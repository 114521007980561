//import localization_de from './assets/localizations/de.json' assert {type: 'JSON'}

export default {
  localize(key, locale = "de") {
    var localizedString = "";
    var jsonFile = null;

    // Parse correct file. JSON.parse() should usually cache the data
    switch (locale) {
      case "de":
        jsonFile = require("../assets/localizations/de.json");
        break;
      default:
        console.log("Localization file not found!");
    }

    if (key && key.length > 0 && jsonFile && jsonFile[key]) {
      localizedString = jsonFile[key];
    }

    return localizedString;
  },
  displaySuccessToast(context, message = null) {
    if (!message) {
      message = "Erfolgreich gespeichert";
    }

    context.$buefy.toast.open({
      message: message,
      type: "is-success",
    });
  },
  displayErrorToast(context, error) {
    let message;

    if (typeof error === "string") {
      message = error;
    } else {
      message = "Fehler: " + error.response.data.reason;

      if (error.response.status === 500) {
        message = "Ein unerwarteter Fehler ist aufgetreten.";
      }
    }

    context.$buefy.toast.open({
      message: message,
      type: "is-danger",
    });
  },
  createEuroStringFromCents(cents) {
    return this.createAmountStringFromCents(cents) + " €";
  },
  createAmountStringFromCents(cents) {
    return this.createAmountStringFromEuro(cents / 100.0);
  },
  createAmountStringFromEuro(euro) {
    // 123456 => "123.456,00"
    return euro
      .toFixed(2) // add 2 zeros at the end
      .replace(".", ",") // change decimal point to comma
      .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // place a dot every thousand group
  },
  createNumberFromAmountString(amount) {
    return Number(amount.replace(".", "").replace(",", ".")) * 100;
  },
  createAmountStringFromNumber(amount) {
    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }).format(amount);
  },
  createIntegerStringFromNumber(amount) {
    return new Intl.NumberFormat("de-DE", {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  },
  debounce(execFunction, waitTime) {
    let timer;
    return function (...args) {
      if (timer) {
        clearTimeout(timer);
      }

      const context = this;
      timer = setTimeout(() => {
        execFunction.apply(context, args);
      }, waitTime);
    };
  },
  downloadFile(context, url) {
    return context
      .$api({
        url: url,
        method: "GET",
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[0]
        );
        link.click();

        URL.revokeObjectURL(href);
      });
  },
  async downloadFileWithNameFetch(context, urlFilenameFetch, urlFileDownload) {
    let filename = null;
    let filenameFetch = context
      .$api({
        url: urlFilenameFetch,
        method: "GET",
      })
      .then((response) => {
        filename = response.data;
      });

    let fileObjectURL = null;
    let fileDownload = context
      .$api({
        url: urlFileDownload,
        method: "GET",
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        fileObjectURL = URL.createObjectURL(response.data);
      });

    return Promise.all([filenameFetch, fileDownload]).then(() => {
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = fileObjectURL;
      link.setAttribute("download", filename);
      link.click();

      URL.revokeObjectURL(fileObjectURL);
    });
  },
  saveDataToLocalStorage(key, data) {
    if (data !== null) {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.removeItem(key);
    }
    
  },
  getParsedDataFromLocalStorage(key, option = null) {
    let storedValue = localStorage.getItem(key);

    try {
      let parsedValue = JSON.parse(storedValue);

      if (
        (option === "number" && isNaN(parsedValue)) ||
        (option === "object" && parsedValue && typeof parsedValue !== "object")
      ) {
        return null;
      }

      return parsedValue;
    } catch (e) {
      return null;
    }
  },
  getParsedPageFromLocalStorage(key) {
    let storedValue = localStorage.getItem(key);

    try {
      let parsedValue = JSON.parse(storedValue);

      return parsedValue && !isNaN(parsedValue) ? parsedValue : 1;
    } catch (e) {
      return 1;
    }
  },
  createCleanSearchParamsString(searchObject) {
    if (searchObject == null) {
      return "";
    }

    let formattedParams = Object.fromEntries(
      Object.entries(searchObject).filter(([, value]) => {
        return value != null && value !== "";
      })
    );

    return new URLSearchParams(formattedParams).toString();
  },
  addLocaleTimezoneToDate(value) {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;

    return new Date(value.getTime() + timezoneOffset);
  },
  removeTimezoneForDate(value) {
    const timezoneOffset = value.getTimezoneOffset() * 60000;

    return new Date(value.getTime() - timezoneOffset);
  },
};
