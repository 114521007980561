import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    company: null,
    schedulingsToValidateCount: 0,
    schedulingsToValidateReloadEvent: null,
  },
  getters: {
    company(state) {
      return state.company;
    },
    assignmentOfRightsGenus(state) {
      switch (state.company?.assignmentOfRightsGenus) {
        case "der":
          return "den";
        case "die":
          return "die";
        default:
          return "das";
      }
    },
    assignmentOfRightsDesignation(state) {
      if (state.company?.assignmentOfRightsDesignation) {
        return state.company.assignmentOfRightsDesignation;
      }
      return "Rahmenvertrag";
    },
    schedulingsToValidateCount(state) {
      return state.schedulingsToValidateCount;
    },
    schedulingsToValidateReloadEvent(state) {
      return state.schedulingsToValidateReloadEvent;
    },
  },
  mutations: {
    initialiseStore(state) {
      let companyItem = localStorage.getItem("company");
      let company =
        companyItem !== "" && companyItem != "undefined"
          ? JSON.parse(localStorage.getItem("company"))
          : null;
      if (company) {
        state.company = company;
      }

      let schedulingsToValidateCount = JSON.parse(
        localStorage.getItem("schedulingsToValidateCount")
      );
      if (schedulingsToValidateCount) {
        state.schedulingsToValidateCount = schedulingsToValidateCount;
      }
    },
    setCompany(state, company) {
      if (company !== undefined) {
        localStorage.setItem("company", JSON.stringify(company));
        state.company = company;
      }
    },
    setSchedulingsToValidateCount(state, schedulingsToValidateCount) {
      localStorage.setItem(
        "schedulingsToValidateCount",
        JSON.stringify(schedulingsToValidateCount)
      );
      state.schedulingsToValidateCount = schedulingsToValidateCount;
    },
    setSchedulingsToValidateReloadEvent(state, event) {
      state.schedulingsToValidateReloadEvent = event;
    },
  },
  actions: {
    selectCompany({ commit }, company) {
      commit("setCompany", company);
    },
    updateSchedulingsToValidateCount({ commit }, schedulingsToValidateCount) {
      commit("setSchedulingsToValidateCount", schedulingsToValidateCount);
    },
    triggerSchedulingsToValidateReloadEvent({ commit }, schedulingsToValidateReloadEvent) {
      commit("setSchedulingsToValidateReloadEvent", schedulingsToValidateReloadEvent);
    },
  },
  modules: {},
});