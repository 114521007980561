import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/components/HomeComponent'),
        meta     : {
            auth: true,
        },
        redirect : '/dash',
        children : [
            {
                path     : '/dash',
                name     : 'dash',
                component: () => import(/* webpackChunkName: "dash" */ '@/components/DashComponent')
            },
            {
                path     : '/archive',
                name     : 'archive',
                component: () => import(/* webpackChunkName: "dash" */ '@/components/ArchiveComponent')
            },
            {
                path     : '/productions',
                name     : 'productions',
                component: () => import(/* webpackChunkName: "production-list" */ '@/components/ProductionListComponent')
            },
            {
                path     : '/productions/:production_id/edit',
                name     : 'production-edit',
                props    : route => ({
                    production_id: route.params.production_id
                }),
                component: () => import(/* webpackChunkName: "production-edit" */ '@/components/ProductionEditComponent')
            },
            {
                path     : '/dubbing-actors',
                name     : 'dubbing-actors',
                component: () => import(/* webpackChunkName: "dubbing-actor-list" */ '@/components/DubbingActorListComponent')
            },
            {
                path     : '/dubbing-actors/:dubbing_actor_id/edit',
                name     : 'dubbing-actor-edit',
                props    : route => ({
                    dubbing_actor_id: String(route.params.dubbing_actor_id)
                }),
                component: () => import(/* webpackChunkName: "dubbing-actor-edit" */ '@/components/DubbingActorEditComponent.vue')
            },
            {
                path     : '/companies',
                name     : 'companies',
                component: () => import(/* webpackChunkName: "company-list" */ '@/components/CompanyListComponent')
            },
            {
                path     : '/companies/:company_id/edit',
                name     : 'company-edit',
                props    : route => ({
                    company_id: route.params.company_id
                }),
                component: () => import(/* webpackChunkName: "company-edit" */ '@/components/CompanyEditComponent')
            },
            {
                path     : '/dispatchings',
                name     : 'dispatchings',
                component: () => import(/* webpackChunkName: "company-list" */ '@/components/DispatchingListComponent')
            },
            {
                path     : '/dispatchings/:dispatching_id/edit',
                name     : 'dispatching-edit',
                props    : route => ({
                    dispatching_id: route.params.dispatching_id,
                    dispatching_input: route.params.dispatching_input
                }),
                component: () => import(/* webpackChunkName: "company-edit" */ '@/components/DispatchingEditComponent')
            },
            {
                path     : '/company-settings',
                name     : 'company-settings',
                component: () => import(/* webpackChunkName: "company-settings" */ '@/components/CompanySettingsComponent')
            },
            {
                path     : '/studios',
                name     : 'studio-list',
                component: () => import(/* webpackChunkName: "studio-list" */ '@/components/StudioListComponent')
            },
            {
                path     : '/contractors',
                name     : 'contractor-list',
                component: () => import(/* webpackChunkName: "contractor-list" */ '@/components/ContractorListComponent')
            },
            {
                path     : '/scheduling-validations',
                name     : 'scheduling-validations',
                component: () => import(/* webpackChunkName: "scheduling-validations" */ '@/components/SchedulingValidationListComponent')
            },
            {
                path     : '/invoices',
                name     : 'invoices',
                component: () => import(/* webpackChunkName: "invoices" */ '@/components/InvoicesComponent')
            },
            {
                path     : '/users',
                name     : 'users',
                component: () => import(/* webpackChunkName: "user-list" */ '@/components/UsersListComponent')
            },
            {
                path     : '/users/:user_id/edit',
                name     : 'user-edit',
                props    : route => ({
                    user_id: String(route.params.user_id)
                }),
                component: () => import(/* webpackChunkName: "user-edit" */ '@/components/UserEditComponent')
            },
            {
                path     : '/schedulings/:scheduling_id/edit',
                name     : 'scheduling-edit',
                props    : route => ({
                    scheduling_id      : route.params.scheduling_id,
                    scheduling_input   : route.params.scheduling_input,
                    dispatching_input  : route.params.dispatching_input,
                    is_dispatching_mode: route.params.is_dispatching_mode,
                    is_update_mode     : route.params.is_update_mode,
                    start_at_input     : route.params.start_at_input
                }),
                component: () => import(/* webpackChunkName: "scheduling-edit" */ '@/components/SchedulingEditComponent')
            },
            {
                path     : '/stats',
                name     : 'stats',
                component: () => import(/* webpackChunkName: "stats" */ '@/components/StatsComponent')
            },
            {
                path     : '/invoice-months',
                name     : 'invoice-months',
                component: () => import(/* webpackChunkName: "stats" */ '@/components/InvoiceMonthEditComponent')
            },
        ]
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path     : '/login',
        name     : 'login',
        component: () => import(/* webpackChunkName: "login-page" */ '@/components/LoginPage')
    },
    {
        path     : '/password-reset-request',
        name     : 'password-reset-request',
        component: () => import(/* webpackChunkName: "password-reset-request-page" */ '@/components/PasswordResetRequestPage')
    },
    {
        path     : '/password-reset',
        name     : 'password-reset',
        props    : route => ({token: route.query.token, company_id: route.params.company_id}),
        component: () => import(/* webpackChunkName: "password-reset-page" */ '@/components/PasswordResetPage')
    }


]

// (to, from, savedPosition) param list
const scrollBehavior = () => {
    return {x: 0, y: 0}
}

const router = new VueRouter({
                                 mode           : 'history',
                                 base           : process.env.BASE_URL,
                                 linkActiveClass: 'is-active',
                                 routes         : routes,
                                 scrollBehavior : scrollBehavior,
                             })

export default router